import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

export const breakpoints = {
    small: "@media only screen and (max-width: 22.4357rem)",
    medium: "@media only screen and (min-width: 31.3125rem)",
    large: "@media only screen and (min-width: 48rem)",
    extralarge: "@media only screen and (min-width: 70.375rem)"
}

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Barlow&family=Barlow+Condensed:wght@300;400&display=swap');

    *, ::before, ::after {
        box-sizing: border-box;
    }

    body {
        font-family: 'Barlow', sans-serif;
        margin: 0;
        padding: 0;
    }

    a { 
        text-decoration: none;
    }

    p {
        line-height: 1.5rem;
        font-size: 1.125rem;
        margin: 0 0 1rem;
    }

    table {
        margin: 2rem 0;
    }

    td {
        border: #2A8236 2px solid;
    }

    td {
        padding: 1rem;
        vertical-align: top;
        display: block;

        ${breakpoints.large} {
            display: table-cell;
        }
    }

    tr > td:first-child {
        background: #2A8236;
        color: white;
    }
`

export const Section = styled.section`
    max-width: 1142px;
    margin: 0 auto;
    padding: 1.5rem 1rem;

    ${breakpoints.large} {
        padding: 2.5rem 1rem;
    }
`

export const SectionBody = styled.div`
    display: flex;
    flex-direction: column;

    ${breakpoints.large} {
        flex-direction: row;

        & > div {
            padding: 0 1.5rem
        }
        & > div:first-child {
            padding: 0 3rem 0 0;
        }
        & > div:last-child {
            padding: 0 0 0 3rem;
        }
    }
`

export const Column = styled.div`
    ${breakpoints.large} {
        flex: 1;
    }
`

export const H1 = styled.h1`
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    margin: 0;
    font-size: 2rem;
`

export const H2 = styled.h2`
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    margin: 0 0 1.5rem;
    color: #5F635F;
    border-left: 4px solid #2A8236;
    padding: 0 0 0.25rem 0.5rem;
    line-height: 2.5rem;
`;

export const H3 = styled.h3`
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0 0 1rem;
    color: #2A8236;
`;

export default function Layout({ children }) {
    return (
        <>
            <GlobalStyle />
            {children}
        </>

    )
}
