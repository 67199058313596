import React from 'react';
import styled from 'styled-components';
import Header from "../header"
import { breakpoints } from '../layout'
import backgroundImage from '../../images/background.jpg';
import { H1 } from '../layout';

const Wrapper = styled.div`
    background: black;
    color: white;
    height: 350px;
    border-bottom: 8px solid #2A8236;

    ${breakpoints.medium} {
        height: 500px;
    }

    ${breakpoints.large} {
        height: 700px;
    }
`

const Image = styled.div`
    background-image: linear-gradient(to bottom, #000 5%, rgba(0,0,0,0)), url(${props => props.background ? props.background : backgroundImage});
    background-size: cover;
    background-position: center;
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;

    ${breakpoints.large} {
        background-image: linear-gradient(to right, #000 5%, rgba(0,0,0,0), #000 95%), url(${props => props.background ? props.background : backgroundImage});
    }
`

const Body = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1142px;
    margin: 0 auto;
    padding: 0 1rem;
`

const ContentWrapper = styled.div`
    display: flex; 
    flex: 1;
    align-items: center;
    justify-content: center;

    ${breakpoints.large} {
        justify-content: flex-start;
    }
`

const Content = styled.div`
    max-width: 20rem;

    ${breakpoints.medium} {
        max-width: 30rem;
    }
    ${breakpoints.large} {
        max-width: 40rem;
    }
`

const Information = styled.div`
    ${breakpoints.medium} {
        padding: 0 3rem 3rem;
    }
    ${breakpoints.large} {
        padding: 0 0 6rem 3rem;
    }
`

const StyledH1 = styled(H1)`
    text-align: center;

    ${breakpoints.medium} {
        font-size: 2.5rem;
    }
    ${breakpoints.large} {
        font-size: 4rem;
        text-align: left;
    }
`

const Highlight = styled.span`
    color: #2A8236;
`

const renderDefaultTitle = () => {
    return (
        <>
            <Highlight>Planning</Highlight> and < Highlight > environment</Highlight > law specialist
        </>
    )
}

const Hero = ({ background, title }) => {
    return (
        <Wrapper>
            <Image background={background}>
                <Body>
                    <Header />
                    <ContentWrapper>
                        <Content>
                            <Information>
                                <StyledH1>
                                    {title ? title : renderDefaultTitle()}
                                </StyledH1>
                            </Information>
                        </Content>
                    </ContentWrapper>
                </Body>
            </Image>
        </Wrapper>
    )
};

export default Hero;
