import React from 'react';

const LogoImage = () => {
    return (
        <svg preserveAspectRatio="xMinYMin meet" width="100%" height="100%" viewBox="0 0 156 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.2298 8.39454C24.1478 8.39454 25.6959 9.12064 26.8741 10.5728C28.0523 11.9976 28.6414 13.8745 28.6414 16.2035C28.6414 18.5325 28.0523 20.3957 26.8741 21.7931C25.7233 23.1905 24.2026 23.8892 22.312 23.8892H17.7499C17.6403 23.8892 17.5855 23.944 17.5855 24.0536V36.8356C17.5855 37.1096 17.4485 37.2466 17.1745 37.2466H15.8182C15.5442 37.2466 15.4072 37.1096 15.4072 36.8356V8.80554C15.4072 8.53154 15.5442 8.39454 15.8182 8.39454H22.2298ZM21.9832 22.0808C23.3258 22.0808 24.4081 21.5465 25.2301 20.4779C26.0521 19.4093 26.4631 17.9982 26.4631 16.2446C26.4631 14.4636 26.0521 13.0388 25.2301 11.9702C24.4081 10.9016 23.3258 10.3673 21.9832 10.3673H17.7499C17.6403 10.3673 17.5855 10.4221 17.5855 10.5317V21.9164C17.5855 22.026 17.6403 22.0808 17.7499 22.0808H21.9832Z" fill="white"/>
            <path d="M38.4456 37.5754C36.6098 37.5754 35.1302 37.0137 34.0068 35.8903C32.9108 34.7395 32.3628 33.2462 32.3628 31.4104V22.3685C32.3628 20.5601 32.9245 19.0805 34.0479 17.9297C35.1713 16.7789 36.6372 16.2035 38.4456 16.2035C40.3088 16.2035 41.8021 16.7789 42.9255 17.9297C44.0489 19.0531 44.6106 20.5327 44.6106 22.3685V31.4104C44.6106 33.2462 44.0489 34.7395 42.9255 35.8903C41.8021 37.0137 40.3088 37.5754 38.4456 37.5754ZM38.4456 35.6437C39.6238 35.6437 40.5828 35.2464 41.3226 34.4518C42.0624 33.6298 42.4323 32.5886 42.4323 31.3282V22.4918C42.4323 21.204 42.0624 20.1628 41.3226 19.3682C40.5828 18.5462 39.6238 18.1352 38.4456 18.1352C37.2948 18.1352 36.3495 18.5462 35.6097 19.3682C34.8973 20.1628 34.5411 21.204 34.5411 22.4918V31.3282C34.5411 32.5886 34.8973 33.6298 35.6097 34.4518C36.3495 35.2464 37.2948 35.6437 38.4456 35.6437Z" fill="white"/>
            <path d="M49.7573 37.2466C49.4833 37.2466 49.3463 37.1096 49.3463 36.8356V8.88774C49.3463 8.61374 49.4833 8.47674 49.7573 8.47674H51.1136C51.3876 8.47674 51.5246 8.61374 51.5246 8.88774V36.8356C51.5246 37.1096 51.3876 37.2466 51.1136 37.2466H49.7573Z" fill="white"/>
            <path d="M58.1757 12.3812C57.5729 12.3812 57.0797 12.1894 56.6961 11.8058C56.3125 11.4222 56.1207 10.929 56.1207 10.3262C56.1207 9.72343 56.3125 9.23024 56.6961 8.84664C57.0797 8.46304 57.5729 8.27124 58.1757 8.27124C58.7785 8.27124 59.2717 8.46304 59.6553 8.84664C60.0389 9.23024 60.2307 9.72343 60.2307 10.3262C60.2307 10.929 60.0389 11.4222 59.6553 11.8058C59.2717 12.1894 58.7785 12.3812 58.1757 12.3812ZM57.5181 37.2466C57.2441 37.2466 57.1071 37.1096 57.1071 36.8356V16.9433C57.1071 16.6693 57.2441 16.5323 57.5181 16.5323H58.8744C59.1484 16.5323 59.2854 16.6693 59.2854 16.9433V36.8356C59.2854 37.1096 59.1484 37.2466 58.8744 37.2466H57.5181Z" fill="white"/>
            <path d="M70.2212 37.4521C68.4402 37.4521 67.0017 36.9726 65.9057 36.0136C64.8371 35.0272 64.3028 33.7668 64.3028 32.2324V31.9858C64.3028 31.7118 64.4398 31.5748 64.7138 31.5748H65.9879C66.2619 31.5748 66.3989 31.7118 66.3989 31.9858V32.2324C66.3989 33.2188 66.7551 34.0408 67.4675 34.6984C68.1799 35.356 69.0978 35.6848 70.2212 35.6848C71.3172 35.6848 72.194 35.356 72.8516 34.6984C73.5366 34.0134 73.8791 33.1366 73.8791 32.068C73.8791 31.2734 73.6873 30.6158 73.3037 30.0952C72.9201 29.5746 72.4406 29.1636 71.8652 28.8622C71.2898 28.5334 70.5089 28.1635 69.5225 27.7525C68.4539 27.3141 67.5771 26.8894 66.8921 26.4784C66.2071 26.04 65.618 25.451 65.1248 24.7112C64.659 23.944 64.4261 22.985 64.4261 21.8342C64.4261 20.1628 64.9467 18.8339 65.9879 17.8475C67.0291 16.8611 68.4128 16.3679 70.139 16.3679C71.8926 16.3679 73.29 16.8748 74.3312 17.8886C75.3724 18.9024 75.893 20.2176 75.893 21.8342V21.9575C75.893 22.2315 75.756 22.3685 75.482 22.3685H74.249C73.975 22.3685 73.838 22.2315 73.838 21.9575V21.752C73.838 20.7382 73.4955 19.8888 72.8105 19.2038C72.1255 18.5188 71.235 18.1763 70.139 18.1763C69.043 18.1763 68.1662 18.5188 67.5086 19.2038C66.851 19.8614 66.5222 20.7245 66.5222 21.7931C66.5222 22.5329 66.7003 23.1494 67.0565 23.6426C67.4127 24.1358 67.8511 24.5331 68.3717 24.8345C68.9197 25.1359 69.6732 25.5058 70.6322 25.9442C71.7556 26.4099 72.6735 26.862 73.3859 27.3004C74.1257 27.7114 74.7559 28.3005 75.2765 29.0677C75.7971 29.8349 76.0574 30.8076 76.0574 31.9858C76.0574 33.6298 75.5231 34.9587 74.4545 35.9725C73.3859 36.9589 71.9748 37.4521 70.2212 37.4521Z" fill="white"/>
            <path d="M89.269 37.2466C88.995 37.2466 88.858 37.1096 88.858 36.8356V8.88774C88.858 8.61374 88.995 8.47674 89.269 8.47674H90.6253C90.8993 8.47674 91.0363 8.61374 91.0363 8.88774V35.1505C91.0363 35.2601 91.0911 35.3149 91.2007 35.3149H100.818C101.092 35.3149 101.229 35.4519 101.229 35.7259V36.8356C101.229 37.1096 101.092 37.2466 100.818 37.2466H89.269Z" fill="white"/>
            <path d="M116.012 27.2593C116.012 27.5333 115.875 27.6703 115.601 27.6703H106.148C106.039 27.6703 105.984 27.7251 105.984 27.8347V31.3282C105.984 32.5886 106.34 33.6298 107.053 34.4518C107.792 35.2464 108.738 35.6437 109.889 35.6437C110.848 35.6437 111.67 35.356 112.355 34.7806C113.04 34.2052 113.492 33.4517 113.711 32.5201C113.793 32.2735 113.944 32.1502 114.163 32.1502L115.478 32.2324C115.588 32.2324 115.684 32.2598 115.766 32.3146C115.848 32.3694 115.875 32.4516 115.848 32.5612V32.7256C115.574 34.2326 114.916 35.4245 113.875 36.3013C112.834 37.1507 111.505 37.5754 109.889 37.5754C108.08 37.5754 106.614 37.0137 105.491 35.8903C104.368 34.7395 103.806 33.2599 103.806 31.4515V22.3685C103.806 20.5601 104.368 19.0805 105.491 17.9297C106.614 16.7789 108.08 16.2035 109.889 16.2035C111.724 16.2035 113.204 16.7789 114.327 17.9297C115.451 19.0805 116.012 20.5601 116.012 22.3685V27.2593ZM109.889 18.1352C108.738 18.1352 107.792 18.5462 107.053 19.3682C106.34 20.1628 105.984 21.204 105.984 22.4918V25.6154C105.984 25.725 106.039 25.7798 106.148 25.7798H113.67C113.779 25.7798 113.834 25.725 113.834 25.6154V22.4918C113.834 21.204 113.464 20.1628 112.724 19.3682C112.012 18.5462 111.067 18.1352 109.889 18.1352Z" fill="white"/>
            <path d="M130.208 16.9433C130.208 16.6693 130.345 16.5323 130.619 16.5323H132.016C132.29 16.5323 132.427 16.6693 132.427 16.9433V37C132.427 39.8222 131.783 41.9731 130.496 43.4527C129.208 44.9323 127.139 45.6172 124.29 45.5076C124.016 45.5076 123.879 45.3707 123.879 45.0967V43.987C123.879 43.713 124.057 43.576 124.413 43.576C126.495 43.576 127.975 43.0143 128.852 41.8909C129.756 40.7675 130.208 39.0824 130.208 36.8356V35.2738C130.208 35.1916 130.181 35.1505 130.126 35.1505C130.071 35.1231 130.03 35.1505 130.002 35.2327C129.537 35.9725 128.934 36.5342 128.194 36.9178C127.482 37.274 126.687 37.4521 125.81 37.4521C124.111 37.4521 122.755 36.8904 121.741 35.767C120.755 34.6162 120.262 33.1229 120.262 31.2871V22.3685C120.262 20.5327 120.755 19.0531 121.741 17.9297C122.755 16.7789 124.111 16.2035 125.81 16.2035C126.687 16.2035 127.495 16.3953 128.235 16.7789C128.975 17.1625 129.564 17.7105 130.002 18.4229C130.057 18.4777 130.098 18.5051 130.126 18.5051C130.181 18.5051 130.208 18.464 130.208 18.3818V16.9433ZM126.386 35.5204C127.509 35.5204 128.427 35.1231 129.139 34.3285C129.852 33.5339 130.208 32.4927 130.208 31.2049V22.4918C130.208 21.204 129.852 20.1628 129.139 19.3682C128.427 18.5462 127.509 18.1352 126.386 18.1352C125.235 18.1352 124.29 18.5462 123.55 19.3682C122.81 20.1628 122.44 21.204 122.44 22.4918V31.2049C122.44 32.4653 122.81 33.5065 123.55 34.3285C124.29 35.1231 125.235 35.5204 126.386 35.5204Z" fill="white"/>
            <path d="M143.082 16.2035C144.946 16.2035 146.398 16.8337 147.439 18.0941C148.508 19.3271 149.042 20.9848 149.042 23.0672V36.8356C149.042 37.1096 148.905 37.2466 148.631 37.2466H147.275C147.001 37.2466 146.864 37.1096 146.864 36.8356V35.356C146.864 35.2738 146.836 35.2327 146.781 35.2327C146.754 35.2053 146.713 35.219 146.658 35.2738C145.644 36.8082 144.055 37.5754 141.891 37.5754C140.438 37.5754 139.178 37.1507 138.109 36.3013C137.041 35.4519 136.506 33.9586 136.506 31.8214C136.506 29.5472 137.137 27.8758 138.397 26.8072C139.685 25.7387 141.384 25.2044 143.493 25.2044H146.699C146.809 25.2044 146.864 25.1496 146.864 25.04V23.1494C146.864 21.615 146.521 20.3957 145.836 19.4915C145.151 18.5873 144.206 18.1352 143 18.1352C142.069 18.1352 141.247 18.4366 140.534 19.0394C139.849 19.6422 139.452 20.4231 139.342 21.3821C139.342 21.6561 139.205 21.7931 138.931 21.7931L137.452 21.7109C137.315 21.7109 137.205 21.6698 137.123 21.5876C137.068 21.5054 137.054 21.4095 137.082 21.2999C137.246 19.7655 137.89 18.5325 139.014 17.6009C140.137 16.6693 141.493 16.2035 143.082 16.2035ZM142.343 35.6437C143.576 35.6437 144.631 35.2464 145.507 34.4518C146.412 33.6572 146.864 32.5338 146.864 31.0816V27.1771C146.864 27.0675 146.809 27.0127 146.699 27.0127H143.493C142.069 27.0127 140.918 27.41 140.041 28.2046C139.192 28.9718 138.767 30.1226 138.767 31.657C138.767 32.9996 139.096 33.9997 139.753 34.6573C140.411 35.3149 141.274 35.6437 142.343 35.6437Z" fill="white"/>
            <path d="M154.233 37.2466C153.959 37.2466 153.822 37.1096 153.822 36.8356V8.88774C153.822 8.61374 153.959 8.47674 154.233 8.47674H155.589C155.863 8.47674 156 8.61374 156 8.88774V36.8356C156 37.1096 155.863 37.2466 155.589 37.2466H154.233Z" fill="white"/>
            <rect width="3.21092" height="45.5951" transform="matrix(-1 0 0 1 3.21094 0)" fill="#2A8236"/>
        </svg>
    )
}

export default LogoImage
