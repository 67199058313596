import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import LogoImage from './LogoImage'
import { breakpoints } from '../layout'

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;


    ${breakpoints.medium} {
        > button {
            display: none;    
        }
    }
`;

const Logo = styled.a`
    height: 2rem;

    ${breakpoints.medium} {
        height: 2.75rem;
    }

    & > svg {
        width: 7rem;
        ${breakpoints.medium} {
            width: 9rem;
        }
    }
`


const Link = styled.a`
    padding: 0.5rem 0;
    color: black;
    font-size: 1.5rem;
    display: flex;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 100;

    &:hover {
        color: #2A8236;
    }

    ${breakpoints.medium} {
        color: white;
    }
`

const MenuButton = styled.button`
    background-color: transparent;
    outline: none;
    border: none;
    > span {
        display: none;
    }
`;

const closeMenu = css`
    &:nth-child(1) {
        transform: rotate(45deg);
        width: 100%;
        top: 12px;
        left: 0px;
    }
    &:nth-child(2) {
        width: 0;
        top: 12px;
        opacity: 0;
    }
    &:nth-child(3) {
        transform: rotate(-45deg);
        width: 100%;
        top: 12px;
        left: 0px;
    }
`;

const openMenu = css`
    &:nth-child(1) {
        top: 5px;
        transform-origin: left center;
    }
    &:nth-child(2) {
        top: 12px;
        transform-origin: left center;
    }
    &:nth-child(3) {
        top: 19px;
        transform-origin: left center;
    }
`;

const MenuIcon = styled.div`
    width: 24px;
    height: 24px;
    position: relative;
    transition: all 0.5s ease-in-out 0s;
    trasform: rotate(0deg);

    & > span {
        display: block;
        position: absolute;
        height: 1px;
        width: 22px;
        left: 1px;
        background: white;
        opacity: 1;
        transition: all 0.25s ease-in-out 0s;
        trasform: rotate(0deg);

        ${(props) => {
        return props.isOpen ? closeMenu : openMenu;
    }}

    }
`;

const NavigationWrapper = styled.div`
    position: fixed;
    background: white;
    bottom: 0;
    top: 64px;
    left: 0;
    z-index: 101;
    width: 70%;
    transition: transform 0.3s;

    ${(props) => {
        return props.isOpen ? `transform: translateZ(0);` : `transform: translate3d(-200%, 0, 0);`
    }}

    ${breakpoints.medium} {
        position: relative;    
        background: transparent;
        width: auto;
        top: 0;
        z-index: 0;
        transform: none;
        transition: none;
    }
`

const Navigation = styled.nav`
    & > a {
        margin: 0 1rem;
    }


    ${(props) => {
        return props.isOpen ? `visibility: visible;` : `visibility: hidden;`
    }}

    ${breakpoints.medium} {
        display: flex;    
        visibility: visible;

        & a:last-child {
            margin: 0 0 0 1rem;
        }
    }
`;

const Overlay = styled.div`
    position: fixed;
    z-index: 100;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};

    ${breakpoints.medium} {
        display: none;    
    }
`;

const PreventScroll = createGlobalStyle`
    body {
        ${({ isOpen }) =>
        isOpen
            ? css`
                overflow: hidden;
                position: fixed;
                width: 100%;

                ${breakpoints.medium} {
                    overflow: visible;    
                    position: static;
                    width:auto;
                }
            `
            : ""}
    }
`;

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [target, setTarget] = useState();

    useEffect(() => {
        if (target != null) {
            const targetElement = document.querySelector(target);
            setTarget(null);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
                window.location = `/${target}`;
            }
        }
    }, [target]);

    const handleMenuClick = () => {
        setIsOpen(!isOpen)
    }

    const handleScrollClick = (e, target) => {
        e.preventDefault();
        setIsOpen(false);
        setTarget(target);
    }

    return (
        <>
            <PreventScroll isOpen={isOpen} />
            <Overlay isOpen={isOpen} onClick={handleMenuClick} />
            <Wrapper>
                <Logo title="Polis Legal" href="/">
                    <LogoImage />
                </Logo>
                <NavigationWrapper isOpen={isOpen}>
                    <Navigation isOpen={isOpen}>
                        <Link href="/#services" onClick={(e) => { handleScrollClick(e, '#services') }}>
                            Services
                        </Link>
                        <Link href="/#about" onClick={(e) => { handleScrollClick(e, '#about') }}>
                            About
                        </Link>
                        <Link href="/#articles" onClick={(e) => { handleScrollClick(e, '#articles') }}>
                            Articles
                        </Link>
                        <Link href="/#contact" onClick={(e) => { handleScrollClick(e, '#contact') }}>
                            Contact
                        </Link>
                    </Navigation>
                </NavigationWrapper>
                <MenuButton onClick={handleMenuClick}>
                    <MenuIcon isOpen={isOpen}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </MenuIcon>
                    <span>Menu</span>
                </MenuButton>
            </Wrapper>
        </>
    )
}

export default Header
